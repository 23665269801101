import React from 'react'

class Header extends React.Component {
    render() {
        return (
            <header id="header">
                <div className="inner">
                    <h1>Welcome to <strong>Kelsey's Cabin</strong></h1>
                </div>
                {/* <Footer /> */}
            </header>
        )
    }
}

export default Header
